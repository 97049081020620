import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import ShopLayout from '../layouts/Shop'

const ImpressumPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <h2>Impressum</h2>
        <h4>Betreiber - Verantwortlicher für den Inhalt</h4>
        <p>
          Nikolaus Sams
          <br />
          Lebensmitteleinzelhandel
          <br />
          Wiener Straße 28 5202 Neumarkt am Wallersee{' '}
        </p>

        <p>
          Telefon: +43 6216 5397
          <br />
          sams@samshofbauer.at
          <br />
          www.samshofbauer.at
        </p>
      </Container>
    </Page>
  </IndexLayout>
)

export default ImpressumPage
